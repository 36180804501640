import { clsx } from 'clsx';
import { twMerge } from 'tailwind-merge';
import { toHex } from '@/lib/string';
export const cn = (...inputs) => twMerge(clsx(inputs));
export const getChartConfig = (data) => {
    const chartConfig = {};
    if (data.length) {
        data.forEach((item) => {
            chartConfig[item.canonicalName] = {
                label: item.name,
                color: item.color || toHex(item.canonicalName),
            };
        });
    }
    return chartConfig;
};
