import React from 'react';
import { useTranslation } from 'react-i18next';
import { badgeVariants } from '@/components/ui/badge';
export const PeriodBadge = ({ value, onClick }) => {
    const { t } = useTranslation();
    const periods = React.useMemo(() => [
        {
            label: t('day'),
            value: 'D',
        },
        {
            label: t('week'),
            value: 'W',
        },
        {
            label: t('month'),
            value: 'M',
        },
        {
            label: t('year'),
            value: 'Y',
        },
        {
            label: t('all'),
            value: 'A',
        },
    ], [t]);
    return periods.map((period, index) => (<button key={`period-option-item-${index}`} className={badgeVariants({ variant: value === period.value ? 'default' : 'outline' })} onClick={() => onClick(period.value)}>
            {period.label}
        </button>));
};
