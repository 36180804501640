import React from 'react';
import { Card, CardContent, CardDescription, CardFooter, CardHeader, CardTitle } from '@/components/ui/card';
import { ChartContainer, ChartLegend, ChartLegendContent, ChartTooltip, ChartTooltipContent, } from '@/components/ui/chart';
import { Label, Pie, PieChart } from 'recharts';
import { useQuery } from '@tanstack/react-query';
import { fetchProductShares } from '@/services/product';
import { useTranslation } from 'react-i18next';
import { Separator } from '@/components/ui/separator';
import { getChartConfig } from '@/lib/utils';
import { toHex } from '@/lib/string';
import { PeriodBadge } from '@/components/period-badges';
import { usePeriod } from '@/hooks/usePeriod';
export const ProductShare = React.memo(() => {
    const { t } = useTranslation();
    const [state, dispatch] = usePeriod();
    const { data = [] } = useQuery({
        queryKey: ['product-shares', state.from, state.to],
        queryFn: () => fetchProductShares({ from: state.from, to: state.to }),
    });
    const handlePeriod = React.useCallback((period) => {
        dispatch({ type: `SET_PERIOD_${period}`, payload: period });
    }, [dispatch]);
    const categories = React.useMemo(() => data.map((item) => ({ ...item, fill: item.color || toHex(item.canonicalName) })), [data]);
    const totalShares = React.useMemo(() => data.reduce((acc, item) => acc + item.share, 0), [data]);
    const renderChart = React.useCallback(() => {
        if (data.length > 0) {
            return (<ChartContainer config={getChartConfig(data)} className="mx-auto aspect-square max-h-[450px]">
                    <PieChart>
                        <ChartTooltip cursor={false} content={<ChartTooltipContent hideLabel/>}/>
                        <ChartLegend content={<ChartLegendContent nameKey="canonicalName" className="flex-wrap"/>}/>
                        <Pie data={categories} dataKey="share" nameKey="canonicalName" innerRadius={60} strokeWidth={5}>
                            <Label content={({ viewBox }) => {
                    if (viewBox && 'cx' in viewBox && 'cy' in viewBox) {
                        return (<text x={viewBox.cx} y={viewBox.cy} textAnchor="middle" dominantBaseline="middle">
                                                <tspan x={viewBox.cx} y={viewBox.cy} className="fill-foreground text-3xl font-bold">
                                                    {totalShares.toLocaleString()}
                                                </tspan>
                                                <tspan x={viewBox.cx} y={(viewBox.cy || 0) + 24} className="fill-muted-foreground">
                                                    {t('product.chart.total', { ns: 'glossary' })}
                                                </tspan>
                                            </text>);
                    }
                }}/>
                        </Pie>
                    </PieChart>
                </ChartContainer>);
        }
        return (<div className="h-[450px] items-center justify-center flex">
                <p className="text-2xl">{t('empty.data', { ns: 'glossary' })}</p>
            </div>);
    }, [categories, data, t, totalShares]);
    return (<Card className="flex flex-col w-full">
            <CardHeader className="items-center">
                <CardTitle>{t('product.chart.title', { ns: 'glossary' })}</CardTitle>
                <CardDescription>{t('product.chart.description', { ns: 'glossary' })}</CardDescription>
            </CardHeader>
            <Separator />
            <CardContent className="flex-1 pb-0">{renderChart()}</CardContent>
            <CardFooter className="justify-center items-center w-full pt-6">
                <PeriodBadge value={state.period} onClick={handlePeriod}/>
            </CardFooter>
        </Card>);
});
